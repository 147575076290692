<template>
  <van-list
    v-model="loading"
    ref="list"
    :finished="finished"
    :immediate-check="false"
    :finished-text="finishedText"
    @load="onLoad"
  >
    <div class="discounts-list">
      <div class="list" v-for="item in list" :key="item.id">
        <div>
          <div class="discount-number" v-if="item.num > 0 && item.status !== 3">
            {{ item.num }}张
          </div>
          <div
            class="discount-number"
            v-else-if="item.use_num > 0 && item.status === 3"
          >
            {{ item.use_num }}张
          </div>
        </div>
        <!-- 今日到期--img -->
        <img
          src="@/assets/discounts/duetoday@2x.png"
          class="expire-icon"
          v-if="item.is_day === 1 && item.status !== 4"
        />
        <!-- 未生效--img -->
        <img
          src="@/assets/discounts/pulldown_icon.png"
          class="pulldown-icon"
          v-if="item.status === 2"
        />
        <div
          class="shade"
          v-if="item.status === 4 || item.status === 3"
          @click="goToCouponInfo(item)"
        >
          <!-- 已过期--img -->
          <img
            src="@/assets/discounts/expire-tag.png"
            class="expire-tag"
            v-if="item.status === 4"
          />
          <!-- 已使用--img -->
          <img
            src="@/assets/discounts/havebeenused@2x.png"
            class="havebeenused"
            v-if="item.status === 3"
          />
          <div class="del-btn" @click="delDiscount(item)" @click.stop>删除</div>
        </div>
        <div class="list-left">
          <div class="list-left-top">
            <div class="discounts">
              {{ item.name }}
            </div>
            <div class="valid-time">
              <span>{{ item.start_time }}</span>
              <span>至</span>
              <span>{{ item.end_time }}</span>
            </div>
          </div>
          <div class="list-left-bottom" @click="goToCouponInfo(item)">
            <div>使用范围：{{ item.range_str }}</div>
            <img src="@/assets/discounts/top-arrow.png" class="top-arrow" />
          </div>
        </div>
        <div class="list-right">
          <div
            class="right-content"
            :class="{ 'right-bottom': status === 4 || status === 3 }"
          >
            <div class="money">
              <Price :price="item.money" integerStyle="28"></Price>
            </div>
            <div class="overbrim-minus">
              {{
                item.full_type === 1 ? `满${item.full_money}元可减` : "无门槛"
              }}
            </div>
            <div
              class="use-btn"
              v-if="item.status === 1"
              @click="instantlyUse(item)"
            >
              立即使用
            </div>
          </div>
        </div>
      </div>
      <div class="blank-box" v-if="list <= 0 && flag === false">
        <div class="blank">
          <img src="@/assets/blank/icon@2x.png" class="blank-img" />
          <span class="blank-text">暂无优惠券</span>
        </div>
      </div>
    </div>
  </van-list>
</template>
<script>
import { getMyDiscount, delDiscount } from "@/api/discounts.js";
import { Toast, Dialog } from "vant";
import { intercept } from "@/common/js/utils.js";
export default {
  props: {},
  data() {
    return {
      active: -1,
      loading: false,
      finished: false,
      list: [],
      blankShow: false,
      page: 1,
      status: 1,
      finishedText: "没有更多优惠券了",
      flag: false,
    };
  },
  mounted() {
    this.initialData();
  },
  watch: {},
  methods: {
    goToCouponInfo(item) {
      console.log(item);
      this.$router.push({
        name: "discounts-info",
        query: {
          status: item.status,
          couponId: item.coupon_id,
          detailId: item.id,
          // grantId: item.grant_id,
        },
      });
    },
    onLoad() {
      if (this.flag) {
        return;
      }
      this.updateData();
    },
    initialData(status) {
      if (this.flag) {
        return;
      }
      this.flag = true;
      this.list = [];
      this.status = status;
      this.finished = false;
      this.showLoadMore = false;
      this.page = 1;
      this.getMyDiscount();
    },
    updateData() {
      ++this.page;
      this.getMyDiscount();
    },
    async getMyDiscount() {
      const res = await getMyDiscount({ status: this.status, page: this.page });
      if (res.code === 1) {
        if (res.data.list.length <= 0 && this.page === 1) {
          this.finished = true;
          this.finishedText = "";
          this.$emit("updateColor", "#fff");
          this.blankShow = true;
        } else {
          if (this.page > 1 && res.data.list.length <= 0) {
            this.finished = true;
            this.finishedText = "没有更多优惠券了";
          } else {
            this.list = [...this.list, ...res.data.list];
            this.$emit("updateColor", "#f9f9f9");
          }
        }
        this.loading = false;
        this.flag = false;
      }
      intercept(this.list);
    },
    unfoldBtn(item, index) {
      if (this.active === item.id) {
        this.active = -1;
      } else {
        this.active = item.id;
      }
    },
    instantlyUse(item) {
      console.log(item);
      this.$router.push({
        name: "discounts",
        query: {
          couponId: item.coupon_id,
          grantId: item.grant_id,
          detailId: item.id,
        },
      });
    },
    delDiscount(item) {
      Dialog.confirm({
        title: "提示",
        message: "您确认删除此优惠券吗？",
      })
        .then(async () => {
          const res = await delDiscount({ id: item.id });
          if (res.code === 1) {
            Toast.success(res.msg);
            this.list = [];
            this.page = 1;
            this.getMyDiscount();
          }
        })
        .catch(() => {});
    },
    firstFliter(str) {
      return str.split(" ")[0];
    },
    lastFliter(str) {
      return str.split(" ")[1];
    },
  },
};
</script>

<style lang="less" scoped>
.discounts-list {
  .list {
    position: relative;
    display: flex;
    margin-bottom: 11px;
    background: url("../../../../assets/discounts/bg@2x.png") no-repeat center /
      100% 100%;
    &:last-child {
      margin-bottom: 0;
    }
    .discount-number {
      position: absolute;
      top: 0;
      left: 0;
      width: 41px;
      height: 19px;
      text-align: center;
      background: linear-gradient(90deg, #ffd871 0%, #f69f1c 100%);
      border-radius: 3px;
      color: #fff;
      line-height: 19px;
    }
    .expire-icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 57px;
      height: 57px;
    }
    .pulldown-icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 68px;
      height: auto;
    }
    .havebeenused {
      position: absolute;
      right: 0;
      top: 0;
      width: 68px;
      height: auto;
    }
    .shade {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 10;
      .expire-tag {
        position: absolute;
        top: 0;
        right: 0;
        width: 68px;
        height: 68px;
      }
      .del-btn {
        position: absolute;
        right: 16px;
        bottom: 12px;
        width: 83px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        border: 1px solid #ed301d;
        border-radius: 34px;
        color: #ed301d;
        font-size: 16px;
      }
    }
  }
}
.list-left {
  flex: 1;
  padding: 17px 14px;
}
.list-left-top {
  padding-bottom: 16px;
  border-bottom: 1px dashed rgb(235, 235, 235);
  .discounts {
    font-size: 17px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
    .tag-green {
      background-color: #59b831;
    }
    .tag-orange {
      background-color: #fda918;
    }
  }
  .valid-time {
    margin-top: 8px;
    color: #999999;
    font-size: 13px;
  }
}
.list-left-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  font-size: 12px;
  color: #666666;
  .describe-content {
    margin-top: 12px;
    .describe-label {
      width: 60px;
    }
  }
  .top-arrow {
    width: 16px;
    height: 16px;
  }
}
.list-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  z-index: 10;
  .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .money {
      display: flex;
      align-items: center;
      color: #ed301d;
      font-size: 12px;
      .money-number {
        font-size: 28px;
      }
    }
    .overbrim-minus {
      color: #666666;
      font-size: 13px;
    }
    .use-btn {
      margin-top: 17px;
      width: 83px;
      height: 34px;
      color: #fff;
      text-align: center;
      line-height: 34px;
      background: rgb(245, 76, 64);
      border-radius: 34px;
    }
  }
  .right-bottom {
    padding-bottom: 25px;
  }
}
.blank-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .blank {
    display: flex;
    flex-direction: column;
    align-items: center;
    .blank-img {
      width: 221px;
      height: 144px;
    }
    .blank-text {
      text-align: center;
      margin-top: 13px;
      color: #aaaaaa;
      font-size: 17px;
    }
  }
}
</style>
