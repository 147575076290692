<template>
  <div class="my-discounts" :style="{ background: backColor }">
    <div class="layout">
      <div class="head">
        <div class="nav">
          <van-icon
            name="arrow-left"
            color="#707070"
            class="nav-left"
            @click="closePopup"
          />
          <span>我的优惠券</span>
        </div>
        <div class="use-tab">
          <div class="tab" @click="changeTab(1)">
            <span :class="{ 'title-color': activeColor === 1 }">未使用</span>
            <div class="tab-border" v-if="activeColor === 1"></div>
          </div>
          <div class="tab" @click="changeTab(4)">
            <span :class="{ 'title-color': activeColor === 4 }">已过期</span>
            <div class="tab-border" v-if="activeColor === 4"></div>
          </div>
          <div class="tab" @click="changeTab(3)">
            <span :class="{ 'title-color': activeColor === 3 }">已使用</span>
            <div class="tab-border" v-if="activeColor === 3"></div>
          </div>
        </div>
      </div>
      <div class="discounts">
        <DiscountsList
          style="height: 100%"
          ref="discount"
          @updateColor="updateColor"
          :status="status"
        ></DiscountsList>
      </div>
      <div class="bottom-btn" @click="openGetCentre" v-if="true">
        <div class="btn-box">
          <span class="btn-text">领券中心</span>
          <van-icon name="arrow" color="#ed301d" size="18" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiscountsList from "../components/discounts-list/discounts-list";
export default {
  name: "my-discounts",
  components: {
    DiscountsList,
  },
  props: {},
  data() {
    return {
      activeColor: 1,
      status: 1,
      backColor: "transparent",
      showPopup: false,
      sourceUrl: "",
    };
  },
  mounted() {},
  methods: {
    openGetCentre() {
      this.$router.push({ name: "get-centre" });
    },
    closePopup() {
      this.$router.go(-1);
    },
    updateColor(color) {
      this.backColor = color;
    },
    changeTab(status) {
      this.$refs.discount.initialData(status);
      this.activeColor = status;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-list {
  height: 100%;
}
.my-discounts {
  min-height: 100vh;
}
.head {
  position: sticky;
  top: 0;
  z-index: 12;
}
.layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  .nav {
    position: relative;
    height: 57px;
    text-align: center;
    line-height: 57px;
    font-size: 21px;
    font-weight: 500;
    border-bottom: 1px solid #ededed;
    background-color: #fff;

    .nav-left {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
    }
  }
}
.use-tab {
  display: flex;
  height: 57px;
  background-color: #fff;
  .tab {
    flex: 1;
    position: relative;
    line-height: 57px;
    text-align: center;
    font-size: 18px;
  }
  .tab-border {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 54px;
    height: 3px;
    background-color: #ed301d;
    border-radius: 3px;
  }
  .title-color {
    color: #ed301d;
  }
}
.discounts {
  position: relative;
  flex: 1;
  margin-top: 12px;
  padding: 0 12px;
}
.bottom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 63px;
  z-index: 10;
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  .btn-box {
    display: flex;
    align-items: center;
  }
  .btn-text {
    font-size: 21px;
    font-weight: 500;
    color: #ed301d;
  }
}
</style>