import request from './ajax'
// 商品模块路由
const Url = '/api/coupon/'

//获取首页可领优惠券
export const getHomeDiscounts = (data) => request(Url + 'grant_list', 'post', { ...data })

//领取优惠券
export const getDiscounts = (data) => request(Url + 'receive_coupon', 'post', { ...data, openLoding: 1 })

//获取可用优惠券商品
export const getDiscountGoods = (data) => request(Url + 'coupon_goods', 'post', { ...data })

//我的优惠券
export const getMyDiscount = (data) => request(Url + 'list', 'post', { ...data, openLoding: 1 })

//删除优惠券
export const delDiscount = (data) => request(Url + 'del_coupon', 'post', { ...data, openLoding: 1 })

//获取详情页优惠券
export const getDetailDiscount = (data) => request(Url + 'goods_coupon', 'post', { ...data })

//获取单个优惠券
export const getDiscountInfo = (data) => request(Url + 'info', 'post', { ...data })

//获取领券中心列表
export const getCanReceiveList = (data) => request(Url + 'can_receive', 'post', { ...data, openLoding: 1 })